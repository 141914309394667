import { Button, Typography, TextField } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import * as React from "react";
import ReactMarkdown from 'react-markdown';
import { InitializationParams, RenderMirrorParams, VirtualMirror } from "@luxottica/virtual-mirror";

import { SnackBar } from "../components/SnackBar";
import { DEFAULT_BOX_PADDING } from "../../../config/Padding";
import { CodeBlock } from '../../../helpers/CodeBlock';
import { DEFAULT_UPC, DEFAULT_DIV ,DEFAULT_URL} from "../../../config/Default";
import { DEFAULT_INIT_PARAMS, getRenderEnvironment } from "../../../config/InitOptions";

import './VmBasic.css';
import 'github-markdown-css';
import { ChangeEvent } from "react";

const content = require('./VmBasic.md').default;

let initializationParams: any = {};
Object.assign(initializationParams as any, DEFAULT_INIT_PARAMS);

class VmBasic extends React.Component<{}, { upc?: string, snackbar?: {message?: string, severity?: string} }> {

  protected initLocale = "en-US";

  constructor(props: {}) {
    super(props);
    this.state = {
      upc: DEFAULT_URL,
      snackbar: undefined,
    };
    this.startMirror();
  }

  protected onLanguangeChange(event) {
    this.initLocale = event.target.value;
  }

  protected startMirror() {
    initializationParams.options.locale = this.initLocale;
    console.log("-> initializationparams", initializationParams);

    VirtualMirror.initialize(initializationParams)
      .then(() => {
        const environment = getRenderEnvironment();
        const params: RenderMirrorParams = {
          target: DEFAULT_DIV.target,
          upc: this.state.upc,
          options: {
            environment: environment as any,
          },
        };
        return VirtualMirror.renderMirror(params);
      })
      .catch((e) => {
        this.setState({
          snackbar: {
            message: e.message || e,
            severity: "error",
          },
        });
        console.error(e);
      });
  }

  protected closeMirror() {
    VirtualMirror.closeMirror(DEFAULT_DIV)
    .then(() => {
      this.setState({
        snackbar: {
          message: 'close mirror'
        },
      });
    });
  }

  protected fitMeBetter() {
    VirtualMirror.fitMeBetter()
    .then(() => {
    });
  }

   protected reset(newUPC) {
    VirtualMirror.renderMirror({
        target: 'target-mirror',
        upc: newUPC
    })
    .then(() => {
    });
  }

  public render() {
    return (
      <div id="demo" className='markdown-body'>
        <SnackBar message={this.state.snackbar?.message} severity={this.state.snackbar?.severity} />
        <Box p={DEFAULT_BOX_PADDING}>
          <div className="mirror-box">
            <div className="mirror-box-column">
             
             

              {/* space */}
              <div className="mirror-box-buttons"></div>
              {/* vm target */}
              <div id={DEFAULT_DIV.target} className="target-mirror"></div>

                <div className="mirror-box-buttons">
                <Button variant="contained" fullWidth size={"large"} onClick={() => this.fitMeBetter()}>FIT ME BETTER</Button>
               {/* <Button variant="contained" fullWidth size={"large"} onClick={() => this.reset('713132436714')}>switch</Button>*/}
              </div>
              <div className="mirror-box-buttons">
              </div>
            </div>
          </div>
        </Box>
      </div>

    )
  }

}

export { VmBasic };

