import {
  InitializationOptions,
  GlassesEnvironment,
} from "@luxottica/virtual-mirror";

const featureLicenseEnvironment = 'PRODUCTION' as any;

const keys = {
  ACCEPTANCE: 'b5188800-6427-47f7-8af4-9621b12dcae3', // aka acceptance
  STAGING: 'c400fbeb-ed85-40c3-933f-807001906bda', // aka test
  PRODUCTION: 'd6f8b43d-0251-4f69-a883-c875697c65d2',
}

const DEFAULT_INIT_OPTIONS: InitializationOptions = {
  brand: 'demo-all',
  locale: 'en-US',
  storeId: 'vm-guide',
  channel: 'luxdeepblue',
  storeRegion: 'luxdeepblueStoreRegion',
  storeCompany: 'luxdeepblueStoreCompany',
  globalStoreId: 'luxdeepblueGlobalStoreId',
  key: keys[featureLicenseEnvironment],
  environment: featureLicenseEnvironment,
}

const DEFAULT_USER_ID = "";

const DEFAULT_INIT_PARAMS = {
  options: DEFAULT_INIT_OPTIONS,
}

const getEnvFromURL = () => {
  const url = window.location.href;
  const urlObj = new URL(url);
  const env = urlObj.searchParams.get('env');
  const glassesEnv = urlObj.searchParams.get('glassesEnv');
  const catalogEnv = urlObj.searchParams.get('catalogEnv');
  const wasmEnv = urlObj.searchParams.get('wasmEnv');
  return {
    env,
    glassesEnv,
    catalogEnv,
    wasmEnv,
  };
}

const getRenderEnvironment = () => {
  const {
    env,
    glassesEnv,
    catalogEnv,
    wasmEnv,
  } = getEnvFromURL();
  // available environmnets: TEST, PROD
  const environment = {
    glasses: env || glassesEnv || 'PROD',
    catalog: env || catalogEnv || 'PROD',
    wasm: env || wasmEnv || 'PROD',
  };
  return environment;
}

const defaultInitOptions = () => {
  const {
    catalogEnv,
  } = getEnvFromURL();

  return {
    key: keys[featureLicenseEnvironment],
    catalogueEnvironment: catalogEnv as GlassesEnvironment,
    environment: featureLicenseEnvironment,
  }
}

const getKey = () => {
  return keys[featureLicenseEnvironment];
}

export {
  DEFAULT_INIT_OPTIONS,
  DEFAULT_USER_ID,
  DEFAULT_INIT_PARAMS,
  defaultInitOptions,
  getRenderEnvironment,
  getKey,
};