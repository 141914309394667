import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5)
  },
  bar: {
    padding: theme.spacing(0.5),
    width: '80vw',
  },
  info: {
    backgroundColor: "#2196f3",
    padding: theme.spacing(0.5),
    width: '100%',
    color: 'white',
  },
  error: {
    backgroundColor: "#f44336",
    padding: theme.spacing(0.5),
    width: '100%',
    color: 'white',
  }
}));

function Message(props) {
  const classes = useStyles();
  return <div {...props} className={props.severity === "info" ? classes.info: classes.error} />;
}

export function SnackBar(props: { message: string, severity?: string }) {
  const [snackPack, setSnackPack] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);

  React.useEffect(() => {
    if (!props.message) {
      return;
    }
    setSnackPack((prev) => [...prev, { message: props.message, key: new Date().getTime() }]);
  }, [props.message]);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don"t have an active one
      setMessageInfo({ message: props.message, key: new Date().getTime() });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  /* const handleClick = (message) => () => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  }; */

  const handleClose = () => {
    /* if (reason === "clickaway") {
      return;
    } */
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const classes = useStyles();
  return (
    <Snackbar
      className={classes.bar}
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      autoHideDuration={6000000000}
      onClose={handleClose}
      onExited={handleExited}
    >
      <Message onClose={handleClose} severity={props.severity ? props.severity : "info"}>
        {messageInfo ? messageInfo.message : ""}
      </Message>
    </Snackbar>
  );
}